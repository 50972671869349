<template>
  <div class="modal">
    <div class="modal-header">
      Betriebliche Ferienbetreuung
    </div>
    <div class="break-normal">
      Arbeit und Ferienzeit stellt für Unternehmen und ihre Mitarbeiter*innen regelmäßig eine Herausforderung dar. Wir
      erleichtern Ihnen den Unternehmensalltag und unterstützen Sie und Ihre Mitarbeiter*innen in dieser Zeit bei der
      Kinderbetreuung. Von der Planung bis zur Durchführung – Sie erhalten bei uns alles aus einer Hand!
      <br><br>
      Machen auch Sie Ihr Unternehmen für Ihre Mitarbeiter*innen mit einer Betrieblichen Ferienbetreuung nachhaltig
      attraktiv.
      <br><br>
      Gerne organisieren wir ein individuell abgestimmtes Ferienprogramm für Ihr Unternehmen.
      <br><br>
      Sie erhalten von uns eine unverbindliche und kostenlose Beratung. Gemeinsam wird eine Erhebung des Bedarfs in
      Ihrem Unternehmen durchgeführt. Auf dieser Grundlage erarbeiten wir ein abgestimmtes Konzept mit den passenden
      Betreuungszeiten, der geeigneten Anzahl der Betreuungsplätze und einem tollen Programm. Bei der Organisation
      stimmen wir gemeinsam die Rahmenbedingungen ab. SoViKo führt für Sie professionell die Betriebliche
      Ferienbetreuung durch. Dabei setzen wir auf qualifizierte und engagierte Fachkräfte.
      <br><br>
      Der Benefit für Ihr Unternehmen liegt auf der Hand: Die Mitarbeiter*innen-Planung in Ihrem Unternehmen wird
      erleichtert. Ihre Belegschaft kann sich entspannt auf die Arbeit konzentrieren. Sie positionieren sich mit Ihrem
      Unternehmen im Wettbewerb um Fachkräfte als attraktiver, innovativer und verantwortungsvoller Arbeitgeber. Eine
      verstärkte Mitarbeiter*innenbindung sowie eine hohe Kontinuität bei der Beschäftigung führt zu weniger
      Fluktuation.
      <br><br>
      Nehmen Sie Kontakt mit uns auf! Wir stehen Ihnen für ein Erstgespräch sehr gerne zur Verfügung! <br>
      Tel.: <a href="tel:+4990744244595" class="underline">09074 / 42 44 595</a> | E-Mail: <a
        href="mailto:info@soviko.de" class="underline">info@soviko.de</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FerienBetreuung',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
